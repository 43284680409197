/* .stripe-checkout {
  position: relative;
} */
.stripe-checkout.submitted form,
.stripe-checkout.submitting form {
  opacity: 0.6;
  pointer-events: none;
}

.stripe-checkout.submitted .success,
.stripe-checkout.submitting .success {
  pointer-events: all;
}

.stripe-checkout.submitting .success .icon {
  opacity: 1;
}

.stripe-checkout.submitting .loading {
  @apply flex;
}

.stripe-checkout.submitted .success > * {
  opacity: 1;
  transform: none !important;
}

.stripe-checkout.submitted .success > :nth-child(2) {
  transition-delay: 0.1s;
}

.stripe-checkout.submitted .success > :nth-child(3) {
  transition-delay: 0.2s;
}

.stripe-checkout.submitted .success > :nth-child(4) {
  transition-delay: 0.3s;
}

/* .stripe-checkout.submitted .success .icon .border, */
.stripe-checkout.submitted .success .icon .checkmark {
  opacity: 1;
  stroke-dashoffset: 0 !important;
}

.stripe-checkout .caption {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  padding: 15px 10px 0;
  color: #aab7c4;
  font-family: Roboto, "Open Sans", "Segoe UI", sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.stripe-checkout .caption * {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

.stripe-checkout .caption .no-charge {
  color: #cfd7df;
  margin-right: 10px;
}

.stripe-checkout .caption a.source {
  text-align: right;
  color: inherit;
  transition: color 0.1s ease-in-out;
  margin-left: 10px;
}

.stripe-checkout .caption a.source:hover {
  color: #6772e5;
}

.stripe-checkout .caption a.source:active {
  color: #43458b;
}

.stripe-checkout .caption a.source  svg {
  margin-right: 10px;
}

.stripe-checkout .caption a.source svg path {
  fill: currentColor;
}

.stripe-checkout form {
  position: relative;
  width: 100%;
  /* max-width: 500px; */
  transition-property: opacity, transform;
  transition-duration: 0.35s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.stripe-checkout form input::-webkit-input-placeholder {
  opacity: 1;
}

.stripe-checkout form input::-moz-placeholder {
  opacity: 1;
}

.stripe-checkout form input:-ms-input-placeholder {
  opacity: 1;
}

.stripe-checkout .error {
  @apply flex flex-row items-center justify-center text-white bg-red-600 text-sm font-semibold;
  display: none;
  padding: 4px 6px;

  .message {
    @apply flex-grow pl-1;
  }
}

.stripe-checkout .error.visible {
  @apply flex mt-2;
  transform: none;
}

.stripe-checkout.submitted .success {
  display: block;
}

.stripe-checkout .loading {
  @apply mx-auto;
  display: none;
  position: fixed;
  top: 0;  left: 0;
  text-align: center;
  background: rgba(255, 255, 255, 0.85);
  z-index: 9999;
}

.stripe-checkout .success {
  @apply flex flex-col items-center justify-center;
  display: none;
  position: fixed;
  background: #fff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding-top: 100px;
  text-align: center;
  pointer-events: none;
  overflow: hidden;
  z-index: 99999;
}

.stripe-checkout .success > * {
  transition-property: opacity, transform;
  transition-duration: 0.35s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  transform: translateY(50px);
}

.stripe-checkout .success .icon {
  transform: translateY(70px) scale(0.75);
}

.stripe-checkout .success .icon svg {
  will-change: transform;
}

/* .stripe-checkout .success .icon .border {
  stroke-dasharray: 251;
  stroke-dashoffset: 62.75;
  transform-origin: 50% 50%;
  transition: stroke-dashoffset 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
  animation: spin 1s linear infinite;
} */

.stripe-checkout .success .icon .checkmark {
  stroke-dasharray: 60;
  stroke-dashoffset: 60;
  transition: stroke-dashoffset 0.35s cubic-bezier(0.165, 0.84, 0.44, 1) 0.35s;
}

.stripe-checkout .success .title {
  margin: 10px 0;
}

.stripe-checkout .success .reset:active {
  transition-duration: 0.15s;
  transition-delay: 0s;
  opacity: 0.65;
}

.stripe-checkout .success .reset svg {
  will-change: transform;
}

footer {
  position: relative;
  max-width: 750px;
  padding: 50px 20px;
  margin: 0 auto;
}

.optionList {
  margin: 6px 0;
}

.optionList li {
  display: inline-block;
  margin-right: 13px;
}

.optionList a {
  color: #aab7c4;
  transition: color 0.1s ease-in-out;
  cursor: pointer;
  font-size: 15px;
  line-height: 26px;
}

.optionList a.selected {
  color: #6772e5;
  font-weight: 600;
}

.optionList a:hover {
  color: #32325d;
}

.optionList a.selected:hover {
  cursor: default;
  color: #6772e5;
}

#payment-request-button {
  width: 100%;
  margin: 10px 0;
}

.stripe-checkout .card-only {
  display: block;
}
.stripe-checkout .payment-request-available {
  display: none;
}

.stripe-checkout .field {
  position: relative;
  width: 100%;
}

.stripe-checkout .field + .field {
  margin-left: 10px;
}

.stripe-checkout label {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.stripe-checkout .input {
  @apply w-full shadow-sm rounded-sm border border-gray-400 bg-white py-2 px-3;
  transition: border-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

  &:focus {
    @apply border-gray-700;
  }
}

.stripe-checkout .submit-button {
  @apply bg-blue-600 text-white uppercase h-16 text-lg mt-4 rounded font-semibold tracking-wider;
  display: block;
  width: 100%;
  cursor: pointer;

  &:hover {
    @apply bg-blue-500;
  }

  &:disabled {
    @apply bg-blue-300;
    cursor: not-allowed;
  }
}

.g-seal {
  max-width: 300px;
}

#card-element {
  padding: 0;
}
.intl-tel-input{
  input[type=tel] {
    @apply pb-0.5;
  }
}
