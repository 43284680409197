@font-face {
    font-family: 'Bespoke Serif';
    src: url('fonts/bespoke-serif/BespokeSerif-Extrabold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Bespoke Serif';
    src: url('fonts/bespoke-serif/BespokeSerif-ExtraboldItalic.otf') format('opentype');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Bespoke Serif';
    src: url('fonts/bespoke-serif/BespokeSerif-Italic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Bespoke Serif';
    src: url('fonts/bespoke-serif/BespokeSerif-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Bespoke Serif';
    src: url('fonts/bespoke-serif/BespokeSerif-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'Estedad';
  src: url('fonts/estedad/Estedad-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Estedad';
  src: url('fonts/estedad/Estedad-ExtraLight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Estedad';
  src: url('fonts/estedad/Estedad-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Estedad';
  src: url('fonts/estedad/Estedad-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Estedad';
  src: url('fonts/estedad/Estedad-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Estedad';
  src: url('fonts/estedad/Estedad-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Estedad';
  src: url('fonts/estedad/Estedad-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Estedad';
  src: url('fonts/estedad/Estedad-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Estedad';
  src: url('fonts/estedad/Estedad-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
